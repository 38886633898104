#pano {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-text-size-adjust: none;
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	-webkit-user-drag: none;
	-webkit-touch-callout: none;
	-ms-content-zooming: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	overflow: hidden;
	/* z-index: -1; */
	/* makes sure things are on top, otherwise most dom elements will interfere with interaction */
}

input,
input:before,
input:after {
	-webkit-user-select: initial;
	-khtml-user-select: initial;
	-moz-user-select: initial;
	-ms-user-select: initial;
	user-select: initial;
}

[list]::-webkit-calendar-picker-indicator,
[list]::-webkit-list-button {
	display: none !important;
	/* makes sure the little down arrow doesn't display on our input & dropdown combobox https://stackoverflow.com/questions/20937475/remove-datalist-dropdown-arrow-in-chrome */
}

.flatNavigationBtn {
	box-shadow: none !important;
	color: #f8f9fa70;
}

.flatNavigationBtn:hover {
	color: #f8f9fa;
}

.flatNavigationBtn {
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: #00000070;
}

.can-select * {
	-moz-user-select: text !important;
	-khtml-user-select: text !important;
	-webkit-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text !important;
	z-index: 20000;
}

.no-select * {
	-moz-user-select: none !important;
	-khtml-user-select: none !important;
	-webkit-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

.project-card:hover {
	box-shadow: 0 1px 6px 0 rgba(52, 55, 57, 0.7);
}

#titleBar .sceneName {
	width: 100%;
	height: 100%;
	line-height: 30px;
	padding: 5px;
	background-color: rgb(58, 68, 84);
	background-color: rgba(58, 68, 84, 0.8);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

/* Hide scene list when only a single scene exists */

body.single-scene #sceneList,
body.single-scene #sceneListToggle {
	display: none;
}

/* Link hotspot */

.abvr-comment-wrapper.open {
	/* opacity: 1; */
	/* Makes sure the this shows above other hotspots */
	z-index: 50000;
}

.abvr-comment-display-wrapper {
	/* overflow-x: auto; */
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
	width: 500px;
}

.abvr-comment-display-wrapper:hover {
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
}

.abvr-comment-display-list {
	max-width: 50vw;
	max-height: 500px;
	display: none;
}

.abvr-comment-editor-form {
	width: 300px;
	max-width: 50vw;
}

.abvr-comment-item {
	/* opacity: 1;  */
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
}

.abvr-comment-item:hover {
	transform: scale(1.015);
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
}

.abvr-comment-item.selected {
	transform: scale(1.015);
	opacity: 1;
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
}

.abvr-comment-item.unselected {
	opacity: 0.4;
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
}

.abvr-comment-caret {
	width: 100%;
	height: 100%;
	/* font-size: 40px; */
	text-align: center;
	/* margin-top: 10px; */
}

.abvr-comment-caret-div {
	text-align: center;
	width: calc(100% - 8px);
	position: absolute;
	background-attachment: local;
	opacity: 1;
	z-index: 20001;
	/* needs to be more than the comment itself */
	overflow-x: hidden;
}

.abvr-comment-caret-div.top {
	top: 0;
}

.abvr-comment-caret-div.bottom {
	/* bottom: 0; */
}

.link-hotspot {
	width: 60px;
	height: 60px;
	margin-left: -30px;
	margin-top: -30px;
	opacity: 0.9;
	-webkit-transition: opacity 0.2s ease;
	transition: opacity 0.2s ease;
}

.link-hotspot.selected {
	opacity: 1;
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
}

.link-hotspot:hover {
	opacity: 1;
	/* This fixes the issue with tooltips not being 'above' the rest of the hotspots around them */
	z-index: 60000;
}

.mobile .link-hotspot {
	width: 70px;
	height: 70px;
}

.link-hotspot-icon {
	width: 100%;
	height: 100%;
	cursor: pointer;
	font-size: 40px;
	color: white;
	text-align: center;
	margin-top: 10px;
	text-shadow: 0px 0px 6px #181818;
}

.link-hotspot-tooltip {
	position: absolute;
	left: 100%;
	top: 10px;
	/* ( 60 - (16 + 2*8) ) / 2 */
	margin-left: 3px;
	font-size: 16px;
	max-width: 300px;
	padding: 8px 10px;
	border-radius: 5px;
	background-color: rgb(58, 68, 84);
	background-color: rgba(58, 68, 84, 0.8);
	color: #fff;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	cursor: pointer;
	opacity: 0;
	-ms-transform: translateX(-8px);
	-webkit-transform: translateX(-8px);
	transform: translateX(-8px);
	-webkit-transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s, opacity 0.3s;
	transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s, opacity 0.3s;
}

.mobile .link-hotspot {
	top: 19px;
	/* ( 70 - (16 + 2*8) ) / 2 */
}

.link-hotspot:hover .link-hotspot-tooltip {
	opacity: 1;
	-ms-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

/* Prevent tooltip from triggering */

.link-hotspot-tooltip {
	pointer-events: none;
}

.link-hotspot:hover .link-hotspot-tooltip {
	pointer-events: all;
}

/* Fallback mode without pointer-events (IE8-10) */

.tooltip-fallback .link-hotspot-tooltip {
	display: none;
}

.tooltip-fallback .link-hotspot:hover .link-hotspot-tooltip {
	display: block;
}

.comment-image {
	/* max-width: 450px; no longer using this since it interferes with the max-width 50vw for the comment as a whole when on mobile */
	max-height: 400px;
}

.comment-text {
	/* -ms-text-size-adjust: auto;
	-moz-text-size-adjust: auto;
	-webkit-text-size-adjust: auto;
	text-size-adjust: auto; */
	-moz-user-select: text;
	-khtml-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
	-webkit-user-drag: none;
	-webkit-touch-callout: default;
	pointer-events: auto;
}

.comment-spinner {
	width: 70%;
	height: 70%;
	cursor: pointer;
	font-size: 25px;
	text-align: center;
	margin-top: 8px;
	margin-left: 8px;
	text-shadow: 0px 0px 6px #181818;
}

.comment-state-btn {
	min-width: 32px;
	min-height: 32px;
}

.nav-map-dot *:hover {
	fill: yellow;
}

/* Info hotspot */

.info-hotspot {
	line-height: 1.2em;
	opacity: 0.9;
	-webkit-transition: opacity 0.2s 0.2s;
	transition: opacity 0.2s 0.2s;
}

.info-hotspot:hover {
	opacity: 1;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.info-hotspot.visible {
	opacity: 1;
}

.info-hotspot .info-hotspot-header {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	background-color: rgb(103, 115, 131);
	cursor: pointer;
	-webkit-transition: width 0.3s ease-in-out 0.5s, border-radius 0.3s ease-in-out 0.5s;
	transition: width 0.3s ease-in-out 0.5s, border-radius 0.3s ease-in-out 0.5s;
}

.mobile .info-hotspot .info-hotspot-header {
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

.desktop.info-hotspot .info-hotspot-header:hover {
	width: 260px;
	border-radius: 5px;
	-webkit-transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
	transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}

.desktop .info-hotspot.visible .info-hotspot-header,
.desktop.info-hotspot.visible .info-hotspot-header:hover {
	width: 260px;
	border-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	-webkit-transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
	transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}

.info-hotspot .info-hotspot-icon-wrapper {
	width: 40px;
	height: 40px;
}

.mobile .info-hotspot .info-hotspot-icon-wrapper {
	width: 50px;
	height: 50px;
}

.info-hotspot .info-hotspot-icon {
	width: 90%;
	height: 90%;
	margin: 5%;
}

.info-hotspot .info-hotspot-title-wrapper {
	position: absolute;
	left: 40px;
	top: 0;
	width: 0;
	height: 40px;
	padding: 0;
	overflow: hidden;
	-webkit-transition: width 0s 0.4s, padding 0s 0.4s;
	transition: width 0s 0.4s, padding 0s 0.4s;
}

.desktop .info-hotspot.visible .info-hotspot-title-wrapper,
.desktop.info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
	width: 220px;
	padding: 0 5px;
	-webkit-transition: width 0s 0.4s, padding 0s 0.4s;
	transition: width 0s 0.4s, padding 0s 0.4s;
}

.info-hotspot .info-hotspot-title-wrapper:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}

.info-hotspot .info-hotspot-title {
	display: inline-block;
	vertical-align: middle;
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.info-hotspot .info-hotspot-close-wrapper {
	position: absolute;
	left: 260px;
	top: 0;
	height: 40px;
	width: 40px;
	border-top-right-radius: 5px;
	background-color: rgb(78, 88, 104);
	visibility: hidden;
	-ms-transform: perspective(200px) rotateY(90deg);
	-webkit-transform: perspective(200px) rotateY(90deg);
	transform: perspective(200px) rotateY(90deg);
	-ms-transform-origin: 0 50% 0;
	-webkit-transform-origin: 0 50% 0;
	transform-origin: 0 50% 0;
	-webkit-transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s, transform 0.3s 0.3s, visibility 0s 0.6s;
	transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s, transform 0.3s 0.3s, visibility 0s 0.6s;
}

.desktop .info-hotspot.visible .info-hotspot-close-wrapper {
	visibility: visible;
	-ms-transform: perspective(200px) rotateY(0deg);
	-webkit-transform: perspective(200px) rotateY(0deg);
	transform: perspective(200px) rotateY(0deg);
	-webkit-transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s, visibility 0s 0s;
	transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s, visibility 0s 0s;
}

.info-hotspot .info-hotspot-close-icon {
	width: 70%;
	height: 70%;
	margin: 15%;
}

.info-hotspot .info-hotspot-text {
	position: absolute;
	width: 300px;
	height: auto;
	max-height: 200px;
	top: 40px;
	left: 0;
	padding: 10px;
	background-color: rgb(58, 68, 84);
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	overflow-y: auto;
	visibility: hidden;
	/* rotate(90deg) causes transition flicker on Firefox 58 */
	-ms-transform: perspective(200px) rotateX(-89.999deg);
	-webkit-transform: perspective(200px) rotateX(-89.999deg);
	transform: perspective(200px) rotateX(-89.999deg);
	-ms-transform-origin: 50% 0 0;
	-webkit-transform-origin: 50% 0 0;
	transform-origin: 50% 0 0;
	-webkit-transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s, visibility 0s 0.3s;
	transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s, visibility 0s 0.3s;
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.desktop .info-hotspot.visible .info-hotspot-text {
	visibility: visible;
	-ms-transform: perspective(200px) rotateX(0deg);
	-webkit-transform: perspective(200px) rotateX(0deg);
	transform: perspective(200px) rotateX(0deg);
	-webkit-transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s, transform 0.3s 0.3s, visibility 0s 0s;
	transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s, transform 0.3s 0.3s, visibility 0s 0s;
}

/* Info hotspot modal */

.desktop .info-hotspot-modal {
	display: none;
}

.info-hotspot-modal {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 11000 !important;
	background-color: rgba(0, 0, 0, .5);
	line-height: 1.2em;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.2s ease-in-out 0.5s, visibility 0s 0.7s;
	transition: opacity 0.2s ease-in-out 0.5s, visibility 0s 0.7s;
}

.info-hotspot-modal.visible {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.2s ease-in-out, visibility 0s 0s;
	transition: opacity 0.2s ease-in-out, visibility 0s 0s;
}

.info-hotspot-modal .info-hotspot-header {
	position: absolute;
	top: 60px;
	left: 10px;
	right: 10px;
	width: auto;
	height: 50px;
	background-color: rgb(103, 115, 131);
	background-color: rgba(103, 115, 131, 0.8);
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-in-out 0.2s;
	transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal.visible .info-hotspot-header {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease-in-out 0.2s;
	transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal .info-hotspot-icon-wrapper {
	width: 50px;
	height: 50px;
}

.info-hotspot-modal .info-hotspot-icon {
	width: 90%;
	height: 90%;
	margin: 5%;
}

.info-hotspot-modal .info-hotspot-title-wrapper {
	position: absolute;
	top: 0;
	left: 50px;
	right: 50px;
	width: auto;
	height: 50px;
	padding: 0 10px;
}

.info-hotspot-modal .info-hotspot-title-wrapper:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}

.info-hotspot-modal .info-hotspot-title {
	display: inline-block;
	vertical-align: middle;
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.info-hotspot-modal .info-hotspot-close-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	background-color: rgb(78, 88, 104);
	background-color: rgba(78, 88, 104, 0.8);
	cursor: pointer;
}

.info-hotspot-modal .info-hotspot-close-icon {
	width: 70%;
	height: 70%;
	margin: 15%;
}

.info-hotspot-modal .info-hotspot-text {
	position: absolute;
	top: 110px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	padding: 10px;
	background-color: rgb(58, 68, 84);
	background-color: rgba(58, 68, 84, 0.8);
	overflow-y: auto;
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.info-hotspot-modal.visible .info-hotspot-text {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease-in-out 0.4s;
	transition: opacity 0.3s ease-in-out 0.4s;
}

/* View control buttons */

.viewControlButton {
	display: none;
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 40px;
	height: 40px;
	padding: 5px;
	background-color: rgb(103, 115, 131);
	background-color: rgba(103, 115, 131, 0.8);
}

body.view-control-buttons .viewControlButton {
	display: block;
}

/* Hide controls when width is too small */

@media (max-width: 600px) {
	body.view-control-buttons .viewControlButton {
		display: none;
	}
}

.viewControlButton .icon {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 30px;
	height: 30px;
}

/* Center is at margin-left: -20px */

.viewControlButton-1 {
	margin-left: -145px;
}

.viewControlButton-2 {
	margin-left: -95px;
}

.viewControlButton-3 {
	margin-left: -45px;
}

.viewControlButton-4 {
	margin-left: 5px;
}

.viewControlButton-5 {
	margin-left: 55px;
}

.viewControlButton-6 {
	margin-left: 105px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.sidebar {
	margin-right: -350px;
	/* move the entire thing offscreen */
	width: 350px;
	position: absolute;
	height: 100%;
	right: 0;
	top: 0;
	/* bottom: 0; */
	/* padding-top: 75px; */
	/* max-height: 93%; */
	/* needs to be 2 so the arrows for the rendering revs don't flow over the sidebar */
	z-index: 2; 
	transition: all 0.3s;
	overflow: hidden;
	/* Disable horizontal scroll */
}

.sidebar.active {
	margin-right: 0;
}

/* .muted-placeholder::placeholder {
	color: lightgray;
} */